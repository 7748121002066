@import "variables";

.paragraph--type--texte-fond-gris {
  background-color: #f4f4f4;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mainContent {
  display: flex;
  justify-content: space-evenly;
  text-justify: distribute;

  a {
    text-decoration: none;
    color: $violetClair;
  }

  h2 {
    font-size: 2rem;
    scroll-margin-top: $startLayout;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    vertical-align: middle;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 28px;
  }

  li::before {
    align-self: flex-start;
    content: "\2022";
    font-size: 50px;
    color: $violetClair;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: -25px;
    text-shadow: -2px -2px 1px $gris;
  }

  p {
    overflow-wrap: break-word;
  }

  .logo-partenaire {
    display: flex;
    img {
      width: 100%;
      max-width: 200px;
    }
  }
}

h1 {
  color: $violetClair;
}

h2 {
  text-transform: uppercase;
  color: $violetFonce;
}

h3 {
  color: $violetClair;
}

h4 {
  color: $violetClair;
}

.txt-gch-img-drt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 130px;
  flex-shrink: 1 !important;

  @media (max-width: 991px) {
    margin-left: 0;
    .txt-gch-img-drt-body {
      width: 100%;
      flex-shrink: 1;
    }
    .txt-gch-img-drt-img {
      display: none;
      flex-shrink: 0;
    }
  }

  @media (min-width: 992px) {
    .txt-gch-img-drt-body {
      width: 50%;
    }

    .txt-gch-img-drt-img {
      width: 50%;
    }
  }

  .txt-gch-img-drt-img {
    justify-self: flex-end;
    max-height: 650px;
    width: auto;
  }
}

.txt-fnd-gris {
  background-color: $fondGris;
  @media (max-width: 991px) {
    padding: 20px !important;
  }
  padding: 100px !important;
}

.paragraph-images {
  display: flex;
  justify-content: space-around;
  img {
    max-width: 100%;
  }
}

.paragraph-documents {
  padding-left: 50px !important;
}

.img-vignette {
  max-width: 100%;
}

.vignette-card {
  @media (max-width: 991px) {
    width: 80% !important;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 25px;
  overflow: hidden;
  padding: 25px !important;

  a {
    text-decoration: none;
  }

  .lien-vignette {
    text-align: center;
    padding: 10px;
    border-radius: 25px;
    margin: 20px;
    color: white;
    text-decoration: none;
    max-width: 100%;
  }
}

.btn-Bleu {
  background-color: $bleuClair;
}

.btn-Vert {
  background-color: $vert;
}

.btn-Rouge {
  background-color: $rouge;
}

.vignette-card.bordure-Bleu {
  border: 2px solid $bleuClair;
}

.vignette-card.bordure-Vert {
  border: 2px solid $vert;
}

.vignette-card.bordure-Rouge {
  border: 2px solid $rouge;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-justify {
  text-align: justify;
}
