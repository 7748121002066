@import "variables";

.Nav {
  padding: 1em;
  flex-shrink: 1;

  width: 100%;
}

.SideBar {
  display: flex;
  flex-wrap: wrap;
  max-width: 318px;

  @media (min-width: 540px) {
    min-width: 350px;
  }

  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
}

.Navlist {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 2;
  position: sticky;
  top: $startLayout + 16px;
  align-self: flex-start;

  li {
    background-color: $violetFonce;
    text-align: left;
    @media (max-width: 540px) {
      padding: 0.6em;
    }

    padding: 1rem;
    border: 1px solid white;
  }

  .navTitle {
    background-color: $violetClair;
    text-align: center;
    padding: 1rem;
    border: 1px solid white;
    color: white;
  }

  li:hover {
    background-color: $violetClair;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 1em;
    color: white;
  }
  a:visited {
    color: white;
  }
  a:hover,
  a:focus {
    color: white;
  }
}
