@import "variables";

.SiteInformations {
  width: 100%;
  background-color: $violetFonce;
  height: $firstNavbarHeight;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: -1;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }
}
