//couleurs
$violetFonce: #382c6a;
$violetClair: #8165a7;
$bleuClair: #35a8c4;
$vert: #3a9e52;
$rouge: #c7313d;
$white: white;

$fondGris: #f4f4f4;
$gris: #00000033;

//layout
$sidebarLeft: 30%;
$firstNavbarHeight: 42px;
$secondNavbarHeight: 75px;
$navbarHeight: $firstNavbarHeight + $secondNavbarHeight;
$logoHeight: $navbarHeight + 10px;
$startLayout: $logoHeight + 50px;
$sidebarWidth: 339px;
