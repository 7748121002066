@import "variables";

.headerBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $firstNavbarHeight + $secondNavbarHeight;
  position: fixed;
  align-items: center;
  background-color: $violetClair;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.logo {
  max-height: $secondNavbarHeight;
  background-color: white;
  border-radius: 0 0 20px 20px;
  transform: translate(120px, -10px) scale(1.4);
  box-shadow: 0px 3px 6px #00000029;
}

.siteName {
  width: 10%;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  h2 {
    color: white;
    margin-bottom: 0;
    margin-top: 0;
  }
}

// .mainNavbar {
//   top: $firstNavbarHeight;
//   width: 90%;
//   display: flex;
//   justify-content: flex-end;
// }
