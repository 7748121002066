@import "variables";

footer {
  background-color: $violetClair;
  padding: 8px;
  text-align: left;
  position: relative;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
  a, a:hover {
    color: white;
  }


  img{
    max-height: 50px;
  }
}