@import "variables";

.scrollBar {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.scrollBar:before {
  content: "";
  position: absolute;
  border-bottom: 1px $violetClair solid;
  height: 100%;
  width: 100%;
  transform: translateY(-50%);
}

.scrollCircle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $violetClair;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.scrollTop {
  display: flex;
  justify-content: center;
  bottom: 20px;
  z-index: 1000;
  cursor: pointer;
  opacity: 1;
  color: white;
  height: 30px;
  width: 30px;
}
