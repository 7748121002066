@import "variables";

.navbar {
  padding: 0;
}

.navlink {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.navlink:hover {
  color: white;
}

.dropdown-item.active,
.dropdown-item:hover {
  background-color: initial !important;
}

.navMenu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  height: $secondNavbarHeight;
  width: 100%;

  .navbar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @media screen and (max-width: 768px) {
      padding-top: inherit;
    }
  }

  .logolink {
    height: 100%;
    width: 40%;
    @media screen and (max-width: 768px) {
      top: 0;
    }

    a {
      @media screen and (max-width: 1350px) {
        left: 15px;
      }

      @media screen and (max-width: 768px) {
        top: 0;
        left: 0;
        height: $logoHeight - $firstNavbarHeight + 10px;
      }
      display: block;
      height: $logoHeight;
      width: fit-content;
      position: relative;
      left: 135px;
      top: -$firstNavbarHeight;
      z-index: 2;
    }
  }

  .logo {
    height: 100%;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    border-radius: 0 0 25px 25px;
    @media screen and (min-width: 768px) {
      min-width: 318px;
    }
  }

  .Nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    z-index: 0;

    a {
      color: white;
      text-decoration: none;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    .nav-item {
      a {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .Nav {
      padding-top: $firstNavbarHeight;
    }
  }

  @media screen and (max-width: 1199px) {
    .Nav {
      width: 100%;
      flex-direction: column;
    }

    #basic-navbar-nav {
      background-color: $violetFonce;
      flex-direction: column;
      z-index: 0;

      .dropdown-menu {
        background-color: $violetClair;
      }
    }
  }
  @media screen and (min-width: 1350px) {
    #basic-navbar-nav {
      display: flex;
      justify-content: flex-end;
      width: 60%;
    }
    .nav-item {
      height: $secondNavbarHeight;

      a {
        height: 99%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .nav-item:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    background-color: $violetFonce;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0;

    @media screen and (min-width: 1200px) {
      right: 0;
      left: auto;
    }

    @media screen and (max-width: 1199px) {
      right: auto;
      left: auto;
    }
  }

  .navlink {
    height: 100%;
  }

  .nav-link {
    color: white;
  }

  .nav-link:hover,
  .nav-link:focus {
    color: white;
  }
}
